import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../base/http.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SnackbarService } from '../../atom/snackbar/snackbar.service';
import { Meta, Title } from '@angular/platform-browser';
import { SelectArrayPrefectures } from '../../base/prefectures';
import { ContactService } from './contact.service';
import { mergeMap, take, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  public formGroup: FormGroup;
  public prefectures: any = SelectArrayPrefectures;

  constructor(
    private httpService: HttpService,
    private router: Router,
    public snackbarService: SnackbarService,
    private meta: Meta,
    private title: Title,
    public service: ContactService
  ) {
    this.createFormGroup();
  }

  public createFormGroup() {
    this.formGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      kana: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      tel: new FormControl('', [Validators.required]),
      fax: new FormControl('', []),
      postcode: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      address1: new FormControl('', [Validators.required]),
      address2: new FormControl('', [Validators.required]),
      content: new FormControl('', [Validators.required]),
    });
  }

  public isLoading = false;
  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content: '',
    });
    this.title.setTitle('お問い合わせ');
  }

  public onLoading(isLoading: any) {
    this.isLoading = isLoading;
  }

  onChangeNumber() {
    this.service
      .zipcode(this.formGroup.value.postcode)
      .pipe(
        take(1),
        mergeMap((value: any) => {
          this.formGroup.patchValue({
            address: value.messege.address1,
            address1: value.messege.address2 + value.messege.address3,
          });
          return of(value);
        })
      )
      .subscribe(
        (value: any) => console.log(value),
        (error: any) => console.log(error),
        () => console.log('onCompleted')
      );
  }

  public onAccept() {
    if (this.formGroup.valid) {
      this.httpService.post('contact', this.formGroup.value).subscribe(
        (value) => {
          if (value.stuats === 200) {
            this.router.navigate(['/complete'], {
              queryParams: {
                iconSrc: '/assets/img/vector.png',
                title: 'お問い合わせありがとうございました。',
                messege:
                  '内容を確認次第、3営業日以内に担当者よりご連絡いたします。\n\r恐れ入りますが、今しばらくお待ちください。',
              },
            });
          } else {
            // this.onError.emit(value);
          }
        },
        (error) => console.log(error),
        () => console.log('onCompleted')
      );
    } else {
      this.snackbarService.pushValidationMessage();
    }
  }
}
