<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-wrapper__main">
    <div class="l-complate">
      <div class="l-complate__title">
        <app-title label="お問い合わせ"></app-title>
      </div>

      <div class="l-complate__main">
        <div class="l-complate__main__title" *ngIf="title">
          <h2 class="c-complate-title">
            お問い合わせいただきありがとうございます。
          </h2>
        </div>
        <div class="l-complate__main__content" *ngIf="messeges.length">
          <p>お問い合わせの送信が完了しました。</p>
          <p>
            後日、担当者からご連絡させていただきますので今しばらくお持ちください。
          </p>
          <span class="l-complate__main__detail">
            <a href="/">
              トップに戻る
              <img
                src="./../../../../assets/img/guidance_next.png"
                alt="next"
              />
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
