<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-wrapper__main">
    <div class="l-facility">
      <div class="l-facility__title">
        <app-title label="施設案内"></app-title>
      </div>

      <div class="l-facility__information">
        <div class="l-facility__information__title">
          <h2 class="l-facility__information__title__text">
            <img
              class="c-pc"
              src="./../../../../assets/img/happa.png"
              alt="葉っぱ"
            />
            <img
              class="c-sp"
              src="./../../../../assets/img/happa.png"
              alt="葉っぱ"
            />
            施設情報
          </h2>
        </div>

        <div class="l-facility__information__table">
          <span>施設名</span>
          <span>日中サービス支援型 重度障がい者グループホーム笑恵</span>

          <span>設立</span>
          <span>2023年4月</span>

          <span>運営</span>
          <span>株式会社アイズ</span>

          <span>事業内容</span>
          <span>相談支援事業</span>

          <span>従業員数</span>
          <span>8名</span>

          <span>施設定員</span>
          <span>８名（完全個室）</span>

          <span>所在地</span>
          <span>〒590-0451 大阪府泉南部熊取町野田1丁目15-4</span>

          <span>電話番号</span>
          <span>072-451-2050</span>

          <span>ショートステイ</span>
          <span>1室有（１週間～）</span>
        </div>

        <div class="l-facility__information__detail">
          <div class="l-facility__information__detail__grid">
            <img
              src="./../../../../assets/img/facility-detail-title.png"
              alt="葉っぱ"
            />
            <span>玄関</span>
            <img
              class="c-sp"
              src="./../../../../assets/img/hall-sp.png"
              alt="玄関"
            />
            <img
              class="c-pc"
              src="./../../../../assets/img/hall.png"
              alt="玄関"
            />
          </div>

          <div class="l-facility__information__detail__grid">
            <img
              src="./../../../../assets/img/facility-detail-title.png"
              alt="葉っぱ"
            />
            <span>リビング</span>
            <img
              class="c-sp"
              src="./../../../../assets/img/living-sp.png"
              alt="リビング"
            />
            <img
              class="c-pc"
              src="./../../../../assets/img/living.png"
              alt="リビング"
            />
          </div>

          <div class="l-facility__information__detail__grid">
            <img
              src="./../../../../assets/img/facility-detail-title.png"
              alt="葉っぱ"
            />
            <span>トイレ</span>
            <img
              class="c-sp"
              src="./../../../../assets/img/toilet-sp.png"
              alt="トイレ"
            />
            <img
              class="c-pc"
              src="./../../../../assets/img/toilet.png"
              alt="トイレ"
            />
          </div>

          <div class="l-facility__information__detail__grid">
            <img
              src="./../../../../assets/img/facility-detail-title.png"
              alt="葉っぱ"
            />
            <span>お風呂</span>
            <img
              class="c-sp"
              src="./../../../../assets/img/bath-sp.png"
              alt="お風呂"
            />
            <img
              class="c-pc"
              src="./../../../../assets/img/bath.png"
              alt="お風呂"
            />
          </div>

          <div class="l-facility__information__detail__grid">
            <img
              src="./../../../../assets/img/facility-detail-title.png"
              alt="葉っぱ"
            />
            <span>バルコニー</span>
            <img
              class="c-sp"
              src="./../../../../assets/img/balcony-sp.png"
              alt="バルコニー"
            />
            <img
              class="c-pc"
              src="./../../../../assets/img/balcony.png"
              alt="バルコニー"
            />
          </div>

          <div class="l-facility__information__detail__grid">
            <img
              src="./../../../../assets/img/facility-detail-title.png"
              alt="葉っぱ"
            />
            <span>お部屋</span>
            <img
              class="c-sp"
              src="./../../../../assets/img/room-sp.png"
              alt="お部屋"
            />
            <img
              class="c-pc"
              src="./../../../../assets/img/room.png"
              alt="お部屋"
            />
          </div>
        </div>

        <div class="l-facility__information__flow">
          <div class="l-facility__information__flow__title">
            <h2 class="l-facility__information__flow__title__text">
              <img
                class="c-pc"
                src="./../../../../assets/img/happa.png"
                alt="葉っぱ"
              />
              <img
                class="c-sp"
                src="./../../../../assets/img/happa.png"
                alt="葉っぱ"
              />
              施設見学の流れ
            </h2>
          </div>
          <div class="l-facility__information__flow__description">
            <img
              class="c-pc"
              src="./../../../../assets/img/facility-flow.png"
              alt="お電話または問い合わせフォームより施設に問い合わせ ｜日程調整 | スタッフと一緒に施設内を見学（目安1時間）"
            />
            <img
              class="c-sp"
              src="./../../../../assets/img/facility-flow.png"
              alt="お電話または問い合わせフォームより施設に問い合わせ ｜日程調整 | スタッフと一緒に施設内を見学（目安1時間）"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
