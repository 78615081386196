import { Injectable } from '@angular/core';
import { HttpService } from '../../base/http.service';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private httpService: HttpService) {}

  public zipcode(value: any): any {
    return this.httpService.get('zip/address/' + value);
  }
}
