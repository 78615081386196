import { createClient } from 'microcms-js-sdk';
export const environment = {
  production: true,
  baseURL: 'http://localhost:3000',
  mailTo: 'imajin8280@gmail.com',
  logger: 'logger',
  biller: 'logger',
  otherCategory: 'other',
};
export const client = createClient({
  serviceDomain: 'emu-service',
  apiKey: 'QqCtd3vGZGPKnLz4T19hhlBuKQsgItBXBcBW',
});
